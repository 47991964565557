<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card>
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Сумма сделки" label-for="value">
                  <b-form-input
                    id="value"
                    v-model="inputVal.value"
                    placeholder="0.00"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Срок (мес.)" label-for="period">
                  <b-form-input
                    id="period"
                    v-model="inputVal.period"
                    placeholder="0"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Ставка (годовая)" label-for="rate">
                  <b-form-input
                    id="rate"
                    v-model="inputVal.rate"
                    type="text"
                    placeholder="0"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Номер договора"
                  label-for="document_number"
                >
                  <b-form-input
                    id="document_number"
                    v-model="inputVal.document_number"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="IBAN" label-for="bank_account">
                  <b-form-input
                    id="bank_account"
                    v-model="inputVal.bank_account"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Дата договора"
                  label-for="date_document_start"
                >
                  <b-form-input
                    id="date_document_start"
                    v-model="inputVal.date_document_start"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Дата зачисления денег"
                  label-for="date_payment_start"
                >
                  <b-form-input
                    id="date_payment_start"
                    v-model="inputVal.date_payment_start"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <div>
                  <b-row>
                    <b-col>
                      <b-button
                        variant="primary"
                        class="mr-1"
                        @click="$emit('updateDeal')"
                      >
                        Обновить
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button variant="secondary" @click="updateSchedule">
                        График
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-form-radio-group
                        id="radio-selector"
                        v-model="day_no"
                        :options="radioOptions"
                        name="radio-options"
                        button-variant="secondary"
                        size="sm"
                        stacked="True"
                      ></b-form-radio-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card>
          <h4>Предварительный график</h4>
          <b-table
            :items="schedule"
            :fields="fields"
            show-empty
            empty-text="Нет данных"
            responsive
          />
        </b-card>
      </b-col>
    </b-row>

    <!-- Кредитный Регистр секция -->
    <b-row class="mt-3">
      <b-col md="6">
        <b-card class="credit-register-card h-100">
          <h4 class="mb-3">Кредитный Регистр</h4>

          <b-form-checkbox v-model="enableCreditRegister" class="mb-3">
            Мне надо сделать изменения в КР
          </b-form-checkbox>

          <div :class="{ 'credit-register-disabled': !enableCreditRegister }">
            <!-- Добавить сделку -->
            <div class="mb-4">
              <h5 class="text-muted">Добавить сделку</h5>
              <b-button
                variant="primary"
                :disabled="!enableCreditRegister"
                @click="confirmProcessDealAdd"
              >
                Отправить запрос
              </b-button>
            </div>

            <!-- Добавить дебит -->
            <div class="mb-4">
              <h5 class="text-muted">Добавить дебит</h5>
              <b-button
                variant="primary"
                :disabled="!enableCreditRegister"
                @click="confirmProcessDealDebit"
              >
                Отправить запрос
              </b-button>
            </div>

            <!-- Закрыть сделку -->
            <div>
              <h5 class="text-muted">Закрыть сделку</h5>
              <b-row class="mb-2">
                <b-col md="6">
                  <b-form-group label="Дата закрытия">
                    <b-form-input
                      v-model="closeDealData.stop_date"
                      type="date"
                      :disabled="!enableCreditRegister"
                      placeholder="ДД.ММ.ГГГГ"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Причина закрытия">
                    <b-form-select
                      v-model="closeDealData.stop_reason_type"
                      :options="stopReasonOptions"
                      :disabled="!enableCreditRegister"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                :disabled="!enableCreditRegister || !isCloseDealFormValid"
                @click="confirmProcessDealClose"
              >
                Отправить запрос
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
      
      <b-col md="6">
        <b-card class="credit-register-history-card h-100">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <h4 class="mb-0">История запросов</h4>
            <div>
              <b-spinner v-if="loadingRequests" small class="mr-1"></b-spinner>
              <b-button 
                variant="outline-secondary" 
                size="sm" 
                @click="fetchCreditRegisterRequests" 
                v-b-tooltip.hover 
                title="Обновить"
              >
                <feather-icon icon="RefreshCwIcon" size="14" />
              </b-button>
            </div>
          </div>
          
          <b-table
            :items="creditRegisterRequests"
            :fields="creditRegisterFields"
            responsive
            hover
            striped
            small
            class="mb-0 credit-register-table"
            show-empty
            empty-text="Нет данных"
            no-border-collapse
          >
            <!-- Request Type Column -->
            <template #cell(request_type_display)="data">
              <span class="text-nowrap">{{ data.value }}</span>
            </template>
            
            <!-- Request Data Column -->
            <template #cell(request_data)="data">
              <div v-if="data.value" class="request-data-container">
                <span 
                  v-b-tooltip.hover.html
                  :title="formatRequestDataTooltip(data.value)"
                >
                  <feather-icon icon="InfoIcon" size="14" />
                </span>
              </div>
              <span v-else>-</span>
            </template>
            
            <!-- Response Data Column -->
            <template #cell(response_data)="data">
              <div v-if="data.value" class="response-data-wrapper">
                <b-badge 
                  v-if="data.value.errorcode === '0'" 
                  variant="success"
                  v-b-tooltip.hover 
                  :title="data.value.description || 'Успешно'"
                >
                  OK
                </b-badge>
                <b-badge 
                  v-else 
                  variant="danger"
                  v-b-tooltip.hover 
                  :title="data.value.description || 'Ошибка'"
                >
                  ERR
                </b-badge>
              </div>
              <span v-else>-</span>
            </template>
            
            <!-- Created Date Column -->
            <template #cell(created)="data">
              {{ formatDate(data.value) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: ['value'],
  data() {
    return {
      schedule: [],
      radioOptions: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
      ],
      day_no: 10,
      fields: [
        {
          key: 'payment_order',
          label: 'Порядок платежа',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'balance_amount',
          label: 'Основной Долг',
        },
        {
          key: 'percent_amount',
          label: 'Проценты',
        },
        {
          key: 'amount_to_pay',
          label: 'Общая Сумма Платежа',
        },
      ],
      // Кредитный Регистр данные
      enableCreditRegister: false,
      loadingRequests: false,
      creditRegisterRequests: [],
      creditRegisterFields: [
        {
          key: 'request_type_display',
          label: 'Тип',
          tdClass: 'request-type-column',
          thClass: 'request-type-column',
        },
        {
          key: 'request_data',
          label: 'Запрос',
          tdClass: 'request-data-column',
          thClass: 'request-data-column',
        },
        {
          key: 'response_data',
          label: 'Ответ',
          tdClass: 'response-data-column',
          thClass: 'response-data-column',
        },
        {
          key: 'created',
          label: 'Дата',
          tdClass: 'date-column',
          thClass: 'date-column',
        },
      ],
      closeDealData: {
        stop_date: dayjs().format('YYYY-MM-DD'), // Format for HTML date input
        stop_reason_type: '8',
      },

      stopReasonOptions: [
        { value: null, text: 'Выберите причину' },
        {
          value: '2',
          text: 'Прекращение договора по решению суда',
        },
        {
          value: '3',
          text: 'Прощение долга',
        },
        {
          value: '4',
          text: 'Прекращение договора по иным основаниям, предусмотренным законодательством',
        },
        {
          value: '5',
          text: 'Исключение МФО из реестра МФО, прекращение микрофинансовой деятельности',
        },
        {
          value: '8',
          text: 'Прекращение договора исполнением - добровольно субъектом кредитной истории',
        },
        {
          value: '9',
          text: 'Прекращение договора исполнением - добровольно третьим лицом',
        },
        {
          value: '10',
          text: 'Прекращение договора исполнением - в исполнительном производстве',
        },
        {
          value: '11',
          text: 'Прекращение договора исполнением - иным способом исполнения',
        },
        {
          value: '12',
          text: 'Прекращение договора в результате прекращения обязательств за счет обеспечения исполнения обязательств по нему',
        },
        {
          value: '13',
          text: 'Прекращение договора вследствие получения возмещения от страховой организации (страховщика)',
        },
        {
          value: '14',
          text: 'Предоставление отступного',
        },
        {
          value: '15',
          text: 'Уступка прав требования',
        },
      ],
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isCloseDealFormValid() {
      return (
        this.closeDealData.stop_date &&
        this.closeDealData.stop_reason_type !== null
      )
    },
  },
  created() {
    if (this.inputVal.id) {
      this.getSchedule()
      this.fetchCreditRegisterRequests()
    }
  },
  methods: {
    async getSchedule() {
      const res = await this.$http.get(
        `admin/deal/schedule/?deal=${this.inputVal.id}`
      )
      this.schedule = res.data.results
      this.schedule.push({
        payment_order: 'Итого',
        date: '',
        balance_amount: this.inputVal.total_schedule_balance,
        percent_amount: this.inputVal.total_schedule_percent,
        amount_to_pay: this.inputVal.total_schedule_amount,
      })
    },
    async updateSchedule() {
      try {
        const res = await this.$http.get(
          `admin/deal/${this.inputVal.id}/schedule/${this.day_no}/`
        )
        this.schedule = res.data
        await this.$emit('getDeal')
        this.schedule.push({
          payment_order: 'Итого',
          date: '',
          balance_amount: this.inputVal.total_schedule_balance,
          percent_amount: this.inputVal.total_schedule_percent,
          amount_to_pay: this.inputVal.total_schedule_amount,
        })
        this.$toast.success('Обновлено')
      } catch (e) {
        this.$toast.danger('Ошибка')
      }
    },

    // Кредитный Регистр методы
    confirmProcessDealAdd() {
      this.$bvModal
        .msgBoxConfirm(
          'Вы уверены, что хотите добавить сделку в Кредитный Регистр?',
          {
            title: 'Подтверждение',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Да',
            cancelTitle: 'Отмена',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.processDealAdd()
          }
        })
    },

    async processDealAdd() {
      try {
        const dealId = this.inputVal.id
        const response = await this.$http.post(
          '/credit-register/process-deal-add/',
          {
            deal_id: dealId.toString(),
          }
        )
        // Display response data in toast
        const message =
          response.data?.message ||
          JSON.stringify(response.data) ||
          'Запрос на добавление сделки отправлен успешно'
        this.$toast.success(message)

        // Refresh credit register requests after successful operation
        this.fetchCreditRegisterRequests()
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.error ||
          JSON.stringify(error.response?.data) ||
          'Ошибка при отправке запроса на добавление сделки'
        this.$toast.error(errorMessage)
        console.error(error)
      }
    },

    confirmProcessDealDebit() {
      this.$bvModal
        .msgBoxConfirm(
          'Вы уверены, что хотите добавить дебит в Кредитный Регистр?',
          {
            title: 'Подтверждение',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Да',
            cancelTitle: 'Отмена',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.processDealDebit()
          }
        })
    },

    async processDealDebit() {
      try {
        const dealId = this.inputVal.id
        const response = await this.$http.post(
          '/credit-register/process-deal-debit/',
          {
            deal_id: dealId.toString(),
          }
        )
        // Display response data in toast
        const message =
          response.data?.message ||
          JSON.stringify(response.data) ||
          'Запрос на добавление дебита отправлен успешно'
        this.$toast.success(message)

        // Refresh credit register requests after successful operation
        this.fetchCreditRegisterRequests()
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.error ||
          JSON.stringify(error.response?.data) ||
          'Ошибка при отправке запроса на добавление дебита'
        this.$toast.error(errorMessage)
        console.error(error)
      }
    },

    confirmProcessDealClose() {
      this.$bvModal
        .msgBoxConfirm(
          'Вы уверены, что хотите закрыть сделку в Кредитном Регистре?',
          {
            title: 'Подтверждение',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Да',
            cancelTitle: 'Отмена',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.processDealClose()
          }
        })
    },

    async processDealClose() {
      try {
        const dealId = this.inputVal.id
        const formattedDate = this.formatDateToDDMMYYYY(
          this.closeDealData.stop_date
        )

        const response = await this.$http.post(
          '/credit-register/process-deal-close/',
          {
            deal_id: dealId.toString(),
            stop_date: formattedDate,
            stop_reason_type: this.closeDealData.stop_reason_type,
          }
        )
        // Display response data in toast
        const message =
          response.data?.message ||
          JSON.stringify(response.data) ||
          'Запрос на закрытие сделки отправлен успешно'
        this.$toast.success(message)
        
        // Refresh credit register requests after successful operation
        this.fetchCreditRegisterRequests()
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.error ||
          JSON.stringify(error.response?.data) ||
          'Ошибка при отправке запроса на закрытие сделки'
        this.$toast.error(errorMessage)
        console.error(error)
      }
    },

    formatDateToDDMMYYYY(dateString) {
      if (!dateString) return ''

      // Use dayjs to format the date to DD.MM.YYYY
      return dayjs(dateString).format('DD.MM.YYYY')
    },
    
    // Credit Register Requests methods
    async fetchCreditRegisterRequests() {
      if (!this.inputVal.id || !this.inputVal.client) return
      
      this.loadingRequests = true
      try {
        const clientId = this.inputVal.client

        // Fetch client's credit register requests
        const response = await this.$http.get('/credit-register/requests/', {
          params: { user_id: clientId }
        })
        
        this.creditRegisterRequests = response.data.results || []
      } catch (error) {
        console.error('Error fetching credit register requests:', error)
      } finally {
        this.loadingRequests = false
      }
    },
    
    formatDate(dateString) {
      if (!dateString) return '-'
      return dayjs(dateString).format('DD.MM.YYYY HH:mm')
    },
    
    formatRequestDataTooltip(requestData) {
      if (!requestData) return ''
      
      try {
        // For single-level objects without nesting
        const rows = []
        
        // Create a table format for the tooltip
        rows.push('<table class="tooltip-table">')
        
        Object.entries(requestData).forEach(([key, value]) => {
          rows.push(`<tr><td class="key">${key}:</td><td>${value}</td></tr>`)
        })
        
        rows.push('</table>')
        
        return rows.join('')
      } catch (error) {
        console.error('Error formatting request data tooltip:', error)
        return 'Ошибка форматирования данных'
      }
    },
  },
}
</script>

<style>
.credit-register-card,
.credit-register-history-card {
  border: 1px solid #f0f0f0;
}

.credit-register-disabled {
  opacity: 0.6;
  pointer-events: none;
  filter: grayscale(1);
}

/* Credit Register Table Styles */
.credit-register-table {
  font-size: 0.85rem;
}

.credit-register-table th,
.credit-register-table td {
  padding: 0.4rem 0.5rem;
}

.request-type-column {
  width: 110px;
  max-width: 110px;
}

.request-data-column,
.response-data-column {
  width: 70px;
  max-width: 70px;
  text-align: center;
}

.date-column {
  width: 120px;
  max-width: 120px;
  white-space: nowrap;
}

.request-data-container {
  display: flex;
  justify-content: center;
  cursor: help;
}

.response-data-wrapper {
  display: flex;
  justify-content: center;
}

/* Tooltip Table Styles */
.tooltip-table {
  border-collapse: collapse;
  font-family: monospace;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 4px;
}

.tooltip-table td {
  padding: 2px 6px;
  border-bottom: 1px solid #eee;
  text-align: left;
  white-space: nowrap;
}

.tooltip-table td.key {
  font-weight: bold;
  color: #7367f0;
}
</style>
